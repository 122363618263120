<template>
  <div class="mb-6 d-flex flex-column justify-space-around">
    <div id="editorjs" class="mb-3"></div>
  </div>
</template>

<script>
import agent from '@/api/agent';

import Delimiter from '@editorjs/delimiter';
import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import imageCompression from 'browser-image-compression';
import ChangeCase from 'editorjs-change-case';
import Header from 'editorjs-header-with-alignment';
import IndentTune from 'editorjs-indent-tune';
import Paragraph from 'editorjs-paragraph-with-alignment';
import ColorPlugin from 'editorjs-text-color-plugin';
import ToggleBlock from 'editorjs-toggle-block';
import Title from 'title-editorjs';
import { toast } from 'vue3-toastify';

// Updated import

export default {
  name: 'EditorComponent',
  props: {
    initialContent: {
      type: Object,
      default: () => ({ blocks: [] }),
    },
  },
  data() {
    return {
      editor: null,
      uploadedImages: new Map(),
      existingImages: new Map(),
      currentBlocks: [],
      isEditorReady: false,
      allowedFileTypes: {
        'application/pdf': '.pdf',
        'application/msword': '.doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/vnd.ms-excel': '.xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      },
      maxFileSize: 10 * 1024 * 1024,
    };
  },
  async mounted() {
    try {
      let contentData = this.initialContent;
      if (typeof contentData === 'string') {
        try {
          contentData = JSON.parse(contentData);
        } catch (e) {
          contentData = await this.htmlToBlocks(contentData);
          this.trackExistingImages(contentData.blocks);

          this.initEditor(contentData);
          return;
        }
      }

      if (!contentData || !contentData.blocks) {
        contentData = { blocks: [] };
      }

      this.trackExistingImages(contentData.blocks);
      this.initEditor(contentData);
    } catch (error) {
      console.error('Error in mounted:', error);
      this.initEditor({ blocks: [] });
    }
  },
  watch: {
    initialContent: {
      handler(newContent) {
        if (this.editor && newContent) {
          let contentData = newContent;
          if (typeof newContent === 'string') {
            try {
              contentData = JSON.parse(newContent);
            } catch (e) {
              contentData = { blocks: [] };
            }
          }
          this.editor.blocks.clear();
          this.editor.blocks.render(contentData);
        }
      },
      immediate: false,
    },
  },
  methods: {
    trackExistingImages(blocks) {
      blocks.forEach(block => {
        if (block.type === 'image' && block.data?.file?.url) {
          //regex to check is valid url
          const urlRegex = new RegExp(
            '^(https?:\\/\\/)?' + // protocol (added 's' to support 'https')
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            'localhost|' + // allow 'localhost'
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?' + // optional port
            '(\\/[-a-z\\d%_.~+()\\s]*)*' + // path (added '()', '\\s' for spaces)
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i',
          );

          let fullPath = block.data.file.url;
          const isValidUrl = urlRegex.test(block.data.file.url);
          if (isValidUrl === false) {
            const sanitizedPath1 = block.data.file.url.replace(/^\/+/, ''); // Премахва водещи '/'
            const sanitizedPath2 = import.meta.env.VITE_COMMON_API_URL.replace(/\/+$/, ''); // Премахва крайни '/'
            const encodedPath = encodeURI(sanitizedPath1);
            fullPath = `${sanitizedPath2}/${encodedPath}`;
          }

          const url = fullPath;
          const lastSegment = url.substring(url.lastIndexOf('/') + 1);

          const imageData = {
            url: fullPath,
            name: block.data.file.name || lastSegment,
            imageId: block.data.file.imageId || lastSegment,
            timestamp: new Date().toISOString(),
          };
          block.data.file = imageData;
          this.existingImages.set(block.data.file, imageData);
        }
      });
    },
    async htmlToBlocks(htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const blocks = [];

      doc.body.childNodes.forEach(node => {
        this.processNode(node, blocks);
      });

      return { blocks };
    },
    processNode(node, blocks) {
      if (node.nodeName === 'P' || node.nodeName.startsWith('H')) {
        const images = node.querySelectorAll('img');
        images.forEach(img => {
          // Създайте блок за изображението
          blocks.push({
            type: 'image',
            data: {
              file: {
                url: img.getAttribute('src'),
              },
              caption: img.getAttribute('alt') || '',
            },
          });
          // Премахнете изображението от параграфа
          img.parentNode.removeChild(img);
        });

        // Ако параграфът все още има текст, създайте блок за него
        const textContent = node.innerHTML.trim();
        if (textContent) {
          blocks.push({
            type: 'paragraph',
            data: {
              text: textContent,
            },
          });
        }
      } else if (node.nodeName === 'IMG') {
        // Създайте блок за изображението
        blocks.push({
          type: 'image',
          data: {
            file: {
              url: node.getAttribute('src'),
            },
            caption: node.getAttribute('alt') || '',
          },
        });
      } else if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent.trim();
        if (textContent) {
          blocks.push({
            type: 'paragraph',
            data: {
              text: textContent,
            },
          });
        }
      } else if (node.childNodes && node.childNodes.length) {
        node.childNodes.forEach(childNode => {
          this.processNode(childNode, blocks);
        });
      }
    },
    initEditor(contentData) {
      try {
        this.editor = new EditorJS({
          holder: 'editorjs',
          autofocus: true,
          placeholder: 'Започнете да пишете вашето съдържание тук...',
          tools: {
            header: {
              class: Header,
              inlineToolbar: true,
              config: {
                placeholder: 'Въведете заглавие',
                levels: [1, 2, 3, 4],
                defaultLevel: 2,
                defaultAlignment: 'left',
                tunes: ['textColor', 'backgroundColor'],
              },
              shortcut: 'CMD+SHIFT+H',
            },
            quote: {
              class: Quote,
              inlineToolbar: true,
              shortcut: 'CMD+SHIFT+Q',
              config: {
                quotePlaceholder: 'Въведете цитат',
                captionPlaceholder: 'Автор на цитата',
              },
            },
            linkTool: {
              class: LinkTool,
              config: {
                endpoint: import.meta.env.VITE_API_FETCH_LINK_URL,
              },
            },
            image: {
              class: ImageTool,
              config: {
                uploader: {
                  uploadByFile: async file => {
                    try {
                      const compressedFile = await imageCompression(file, {
                        maxSizeMB: 0.1,
                        maxWidthOrHeight: 800,
                        initialQuality: 0.8,
                        useWebWorker: true,
                        fileType: 'image/webp',
                      });
                      const formData = new FormData();

                      const extensionsToRemove = [
                        '.apng',
                        '.png',
                        '.avif',
                        '.gif',
                        '.jpg',
                        '.jpeg',
                        '.jfif',
                        '.pjpeg',
                        '.pjp',
                        '.svg',
                      ];

                      let processedFileName = compressedFile.name || 'defaultName.jpg';
                      for (const ext of extensionsToRemove) {
                        if (processedFileName.toLowerCase().endsWith(ext)) {
                          processedFileName = processedFileName.slice(0, -ext.length);
                          break;
                        }
                      }

                      const image = new File([compressedFile], processedFileName, {
                        type: compressedFile.type,
                      });
                      formData.append(image.type, image, processedFileName);
                      const response = await agent.Images.uploadImage(formData);

                      if (response.status === 200) {
                        const imageData = {
                          url: response.data.url,
                          name: response.data.name || file.name,
                          originalName: file.name,
                          timestamp: new Date().toISOString(),
                        };

                        this.uploadedImages.set(response.data.url, imageData);

                        return {
                          success: 1,
                          file: {
                            url: response.data.url,
                            name: response.data.name || file.name,
                            imageId: response.data.title,
                          },
                        };
                      }
                      throw new Error('Грешка при качване на изображението');
                    } catch (error) {
                      console.error('Грешка при качване:', error);
                      toast.error('Грешка при качване на изображението');
                      return {
                        success: 0,
                        error: error.message,
                      };
                    }
                  },
                },
              },
            },
            table: {
              class: Table,
              inlineToolbar: true,
              config: {
                rows: 2,
                cols: 3,
              },
            },
            toggle: {
              class: ToggleBlock,
              inlineToolbar: true,
            },
            paragraph: {
              class: Paragraph,
              inlineToolbar: true,
              config: {
                placeholder: 'Въведете текст тук...',
                preserveBlank: true,
              },
            },
            title: Title,
            delimiter: Delimiter,
            changeCase: {
              class: ChangeCase,
              config: {
                showLocaleOption: true,
                locale: 'tr',
              },
            },
            indentTune: IndentTune,
            backgroundColor: {
              class: ColorPlugin,
              config: {
                colorCollections: [
                  '#FFFFFF',
                  '#EEEEEE',
                  '#F5F5DC',
                  '#F0F8FF',
                  '#E6E6FA',
                  '#FFE4E1',
                  '#98FB98',
                  '#87CEEB',
                ],
                defaultColor: '#FFFFFF',
                type: 'background',
                customPicker: true,
              },
            },
            textColor: {
              class: ColorPlugin,
              config: {
                colorCollections: [
                  '#000000',
                  '#EC7878',
                  '#9C27B0',
                  '#673AB7',
                  '#3F51B5',
                  '#0070FF',
                  '#03A9F4',
                  '#00BCD4',
                  '#4CAF50',
                  '#8BC34A',
                  '#CDDC39',
                  '#FFF',
                ],
                defaultColor: '#000000',
                type: 'text',
                customPicker: true,
              },
            },
          },
          data: contentData, // Use the processed contentData
          tunes: ['indentTune', 'delete'],
          shortcuts: {
            delete: ['Backspace', 'Delete'],
          },
          logLevel: 'ERROR',
          onReady: async () => {
            this.isEditorReady = true;
            console.log('Editor is ready');
            const initialData = await this.editor.save();

            this.currentBlocks = initialData.blocks;
            this.setupEventListeners();
          },
          onChange: async (api, event) => {
            await this.handleContentChange(event);
          },
        });
      } catch (error) {
        console.error('Error while creating an editor: ', error);
      }
    },
    async deleteImage(name) {
      try {
        if (!this.uploadedImages.has(name)) {
          console.warn('Image not found in uploadedImages:', name);
          return;
        }

        const response = await agent.Images.deleteImage(name);

        if (response.status === 200) {
          this.uploadedImages.delete(name);
          toast.success('Изображението е изтрито успешно');
        } else {
          throw new Error('Неуспешно изтриване на изображението');
        }
      } catch (error) {
        console.error('Error deleting image:', error);
        toast.error('Грешка при изтриване на изображението');
      }
    },
    setupEventListeners() {
      if (!this.editor || !this.isEditorReady) {
        console.warn('Editor not ready for event listeners');
        return;
      }

      try {
        const editorContainer = document.getElementById('editorjs');
        if (!editorContainer) {
          console.error('Editor container not found');
          return;
        }

        editorContainer.addEventListener('keyup', e => {
          if (e.key === 'Delete' || e.key === 'Backspace') {
            this.checkForDeletedBlocks();
          }
        });

        const toolbarButtons = editorContainer.querySelectorAll('.ce-toolbar__settings-btn');
        toolbarButtons.forEach(button => {
          button.addEventListener('click', () => {
            this.checkForDeletedBlocks();
          });
        });
      } catch (error) {
        console.error('Error setting up event listeners:', error);
      }
    },

    async checkForDeletedBlocks() {
      const currentData = await this.editor.save();

      const removedBlocks = this.currentBlocks.filter(
        oldBlock => !currentData.blocks.some(newBlock => newBlock.id === oldBlock.id),
      );

      for (const block of removedBlocks) {
        if (block.type === 'image' && block.data && block.data.url) {
          const imageUrl = block.data.url;
          if (this.uploadedImages.has(imageUrl)) {
            const imageData = this.uploadedImages.get(imageUrl);
            await this.deleteImage(imageUrl, imageData);
          }
        }
      }

      this.currentBlocks = currentData.blocks;
    },

    async handleContentChange(event) {
      try {
        const currentData = await this.editor.save();
        const removedBlocks = this.currentBlocks.filter(
          oldBlock => !currentData.blocks.some(newBlock => newBlock.id === oldBlock.id),
        );

        for (const removedBlock of removedBlocks) {
          if (removedBlock.type === 'image' && removedBlock.data?.file?.url) {
            const imageUrl = removedBlock.data.file.url;

            if (this.uploadedImages.has(imageUrl)) {
              const imageData = this.uploadedImages.get(imageUrl);
              await this.deleteImage(imageUrl, imageData);
              this.uploadedImages.delete(imageUrl);
            } else if (this.existingImages.has(imageUrl)) {
              const imageData = this.existingImages.get(imageUrl);
              await this.deleteImage(imageUrl, imageData);
              this.existingImages.delete(imageUrl);
            }
          }
        }

        this.currentBlocks = currentData.blocks;
        this.$emit('content-changed', currentData);
      } catch (error) {
        console.error('Error in handleContentChange:', error);
      }
    },

    async deleteImage(url, imageData) {
      try {
        const response = await agent.Images.deleteImage(imageData.name);
        if (response.status === 200) {
          toast.success('Изображението е изтрито успешно');
        } else {
          throw new Error('Неуспешно изтриване на изображението');
        }
      } catch (error) {
        console.error('Error deleting image:', error);
        toast.error('Грешка при изтриване на изображението');
      }
    },
    isAllowedFileType(mimeType) {
      return Object.keys(this.allowedFileTypes).includes(mimeType);
    },
    getFileExtension(filename) {
      return filename.split('.').pop();
    },
    // async uploadFile(formData) {
    //     try {
    //         const response = await agent.Files.upload(formData)
    //         return {
    //             success: true,
    //             fileId: response.data.fileId,
    //             url: response.data.url
    //         }
    //     } catch (error) {
    //         console.error('Грешка при качване:', error)
    //         return {
    //             success: false,
    //             error: error.message
    //         }
    //     }
    // },
    async deleteFile(fileId) {
      try {
        const response = await agent.Files.delete(fileId);
        if (response.success) {
          this.uploadedFiles.delete(fileId);
          toast.success('Файлът е изтрит успешно');
        }
      } catch (error) {
        console.error('Грешка при изтриване на файл:', error);
        toast.error('Грешка при изтриване на файла');
      }
    },
    async saveData() {
      try {
        const outputData = await this.editor.save();
        if (!outputData.blocks.length) {
          toast.error('Не може да се запази празно съдържание');
          return;
        }

        this.$emit('update:content', outputData);
      } catch (error) {
        toast.error(error.message);
      }
    },
    async isEmpty() {
      const outputData = await this.editor.save();

      const isEmptyBlocks = outputData.blocks.every(block => {
        switch (block.type) {
          case 'paragraph':
            return !block.data.text.trim();
          case 'header':
            return !block.data.text.trim();
          case 'image':
            return !block.data.file.url;
          case 'quote':
            return !block.data.text.trim();
          case 'table':
            return !block.data.content.length || block.data.content.every(row => row.every(cell => !cell.trim()));
          case 'list':
            return !block.data.items.length || block.data.items.every(item => !item.trim());
          case 'title':
            return !block.data.text.trim();
          case 'delimiter':
            return true;
          case 'linkTool':
            return !block.data.link.trim();
          case 'toggle':
            return !block.data.text.trim();
          default:
            return true;
        }
      });

      return isEmptyBlocks;
    },
    beforeDestroy() {
      const allImages = new Map([...this.uploadedImages, ...this.existingImages]);
      if (allImages.size > 0) {
        allImages.forEach((imageData, url) => {
          this.deleteImage(url, imageData);
        });
      }

      if (this.editor) {
        this.editor.destroy();
      }
    },
  },
};
</script>

<style scoped>
#editorjs {
  border: 1px solid #ccc;
  padding: 10px;
}
</style>
