<template>
  <div>
    <v-btn color="primary" @click="$refs.fileInput.click()">
      <Icon :icon="plus" /> Добави файлове
    </v-btn>
    <input
      ref="fileInput"
      type="file"
      multiple
      :accept="allowedFileTypes"
      @change="onFileChange"
      class="d-none"
    />
    <v-row class="mt-4">
      <TransitionGroup name="gallery" tag="div" class="d-flex flex-wrap w-100">
        <v-col
          v-for="(file, index) in previewFiles"
          :key="file.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="gallery-item"
        >
          <v-card class="file-card">
            <v-btn icon @click="removeFile(index)" class="delete-btn">
              <Icon :icon="deleteIcon" style="color: red" />
            </v-btn>
            <div class="file-wrapper">
              <div class="file-name">{{ file.name }}</div>
            </div>
          </v-card>
        </v-col>
      </TransitionGroup>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import deleteIcon from '@iconify-icons/mdi/close-circle'
import plus from '@iconify-icons/mdi/plus'
import { Icon } from '@iconify/vue'

interface PreviewFile {
  id: string;
  file: File;
  name: string;
}

const props = defineProps<{
  modelValue: File[] | null;
}>();
const emit = defineEmits<{
  'update:modelValue': [files: File[]];
  fileChange: [files: File[]];
}>();
const fileInput = ref<HTMLInputElement | null>(null);
const previewFiles = ref<PreviewFile[]>([]);

const allowedFileTypes = '.pdf,.doc,.docx,.xls,.xlsx';

const createPreviewForFile = (file: File): PreviewFile => {
  return {
    id: `file_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
    file,
    name: file.name,
  };
};

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const files = Array.from(target.files);
  const currentFiles = props.modelValue || [];
  const updatedFiles = [...currentFiles, ...files];
  // Create previews for new files
  for (const file of files) {
    const preview = createPreviewForFile(file);
    previewFiles.value.push(preview);
  }
  // Emit only fileChange
  emit('fileChange', updatedFiles);
  target.value = '';
};

const removeFile = (index: number) => {
  const newFiles = [...(props.modelValue || [])];
  newFiles.splice(index, 1);
  // Remove only the specific file from previews
  previewFiles.value.splice(index, 1);
  // Emit only fileChange, without update:modelValue
  emit('fileChange', newFiles);
};

watch(
  () => props.modelValue,
  newFiles => {
    if (!newFiles?.length) {
      previewFiles.value = [];
      return;
    }
    // Filter only files that don't have previews yet
    const existingPreviews = new Set(previewFiles.value.map(p => `${p.file.name}-${p.file.size}`));
    const newFilesToPreview = newFiles.filter(
      file => !existingPreviews.has(`${file.name}-${file.size}`),
    );
    if (newFilesToPreview.length > 0) {
      const newPreviews = newFilesToPreview.map(file => createPreviewForFile(file));
      // Add only new previews
      previewFiles.value = [...previewFiles.value, ...newPreviews];
    }
    // Remove previews of files that are no longer present
    const currentFileKeys = new Set(newFiles.map(f => `${f.name}-${f.size}`));
    previewFiles.value = previewFiles.value.filter(preview =>
      currentFileKeys.has(`${preview.file.name}-${preview.file.size}`),
    );
  },
  { deep: true },
);
</script>

<style scoped>
.file-card {
  position: relative;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.file-wrapper {
  position: relative;
  flex: 1;
  min-height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-name {
  text-align: center;
  word-break: break-all;
}
.delete-btn {
  &:hover {
    background-color: transparent !important;
    box-shadow: none;
  }
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  background-color: transparent;
  transition: background-color 0.3s ease;
  span svg {
    font-size: 25px;
  }
}
</style>
