export function formatDateForInput(date: string | number | Date): string {
  if (!date) return '';

  let parsedDate: Date;

  if (typeof date === 'string') {
    if (date.includes('г.')) {
      const [datePart] = date.split(' г.');
      const [dateParts] = datePart.split(',');
      const [day, month, year] = dateParts.split('.');
      parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      parsedDate = new Date(date);
    }
  } else {
    parsedDate = new Date(date);
  }

  if (isNaN(parsedDate.getTime())) {
    return '';
  }

  return parsedDate.toISOString().split('T')[0];
}
