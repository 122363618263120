import type { Article, ArticleData } from '@/models/article';
import type { Banner, NewBannerFormValues } from '@/models/banner';
import type { Category, CategoryUpdateFormValues, NewCategoryFormValues } from '@/models/category';
import { PaginatedResult } from '@/models/pagination';
import type {
  NewUserFormValues,
  Role,
  User,
  UserFormValues,
  UserUpdateFormValues,
} from '@/models/user';

import axios, { type AxiosError, type AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

interface ApiResponse<T> {
  data: T;
  status: number;
}

const responseBody = <T>(response: AxiosResponse<T>): ApiResponse<T> => ({
  data: response.data,
  status: response.status,
});

axios.interceptors.request.use(config => {
  const token = Cookies.get('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async response => {
    const pagination = response.headers['pagination'];
    if (pagination) {
      response.data = new PaginatedResult(response.data, JSON.parse(pagination));
      return response as AxiosResponse<PaginatedResult<unknown>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error.response!;
    switch (status) {
      case 401:
        console.log('Unauthorized');
        break;
      case 403:
        console.log('Forbidden');
        break;
      case 404:
        console.log('Not found');
        break;
      case 500:
        console.log('Server error');
        break;
    }
    return Promise.reject({ data, status });
  },
);

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  patch: <T>(url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Users = {
  login: (user: UserFormValues) => requests.post<User>('/users/login', user),
  getAllUsers: () => requests.get<User[]>('/users'),
  createUser: (user: NewUserFormValues) => requests.post<User>('/users', user),
  updateUserProfile: (user: UserUpdateFormValues) => requests.patch<User>(`users/${user.id}`, user),
  deleteUser: (id: string) => requests.delete<void>(`users/${id}`),
};

const Roles = {
  getAllRoles: () => requests.get<Role[]>('/roles'),
  addRole: (role: Role) => requests.post<Role>('/roles', role),
};

const Articles = {
  getAllArticles: (params: URLSearchParams) =>
    axios.get<PaginatedResult<ArticleData[]>>('/articles', { params }),
  getArticle: (id: string) => requests.get<ArticleData>(`/articles/info/${id}`),
  createArticle: (article: FormData) =>
    axios
      .post<Article>('/articles', article, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(responseBody),
  updateArticle: (article: FormData, id: string) =>
    axios
      .post<ArticleData>(`/articles/${id}`, article, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(responseBody),
  deleteArticle: (id: string) => requests.delete<void>(`articles/${id}`),
};

const Categories = {
  getAllCategories: () => requests.get<Category[]>('/categories'),
  getCategory: (id: number) => requests.get<Category>(`categories/${id}`),
  addCategory: (category: NewCategoryFormValues) =>
    requests.post<Category>('/categories', category),
  deleteCategory: (id: number) => requests.delete<void>(`categories/${id}`),
  updateCategory: (category: CategoryUpdateFormValues) =>
    requests.patch<Category>(`categories/${category.id}`, category),
  updateCategoryHierarchy: (categories: number[], newParentId: number) =>
    requests.patch<void>('/categories/hierarchy', { categories, newParentId }),
};

const Banners = {
  getAllBanners: (params: URLSearchParams) =>
    axios.get<PaginatedResult<Banner[]>>('/banners', { params }),
  getBanner: (id: string) => requests.get<Banner>(`banners/${id}`),
  createBanner: (banner: FormData) => requests.post<Banner>('/banners', banner),
  deleteBanner: (id: string) => requests.delete<Banner>(`banners/delete/${id}`),
  statusChange: (id: string, status: string) =>
    requests.patch<Banner>(`banners/status/${id}`, { status }),
  updateBanner: (id: string, banner: FormData) =>
    requests.patch<Banner>(`banners/update/${id}`, banner),
  getIndexes: () =>
    requests.get<{
      main: number[];
      side: number[];
      content: number[];
    }>('/banners/indexes'),
};

const Images = {
  uploadImage: (image: FormData) =>
    axios
      .post('/articles/upload-image', image, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(responseBody),
  deleteImage: (title: any) => requests.post<void>('articles/delete-image', { title }),
};

const agent = {
  Users,
  Roles,
  Articles,
  Categories,
  Banners,
  Images,
};

export default agent;
