<template>
  <h1>Добавяне на банер</h1>
  <v-divider class="mt-4"></v-divider>
  <v-form @submit.prevent="submitForm" class="mt-8">
    <v-text-field
      v-model="title"
      label="Заглавие на банер *"
      hint="Добавете заглавие на банера"
      :error-messages="titleError"
      required
    ></v-text-field>

    <v-text-field
      v-model="description"
      label="Описание банер"
      hint="Добавете описание на банера"
    ></v-text-field>

    <v-text-field
      v-model="url"
      label="Линк на банер *"
      hint="Добавете линк на банера"
      :error-messages="urlError"
      required
    ></v-text-field>

    <v-select
      v-model="position"
      :items="bannerStore.bannerPositions"
      item-title="label"
      item-value="value"
      label="Позиция *"
      :error-messages="positionsError"
      required
      clearable
    ></v-select>

    <v-text-field
      label="Пореден номер"
      hint="Пореден номер на банера"
      v-model="positionIndex"
      type="number"
      :min="minPositionIndex"
      :max="maxPositionIndex"
      :rules="[rules.required]"
      :error-messages="positionIndexError"
    />
    <v-file-input
      v-model="image"
      label="Изображение"
      :error-messages="imageError"
      placeholder="Моля, изберете снимка"
      required
      accept="image/*"
      @change="onImageChange"
    ></v-file-input>
    <v-img
      v-if="bannerStore.bannerForm.imagePreview"
      :src="bannerStore.bannerForm.imagePreview"
      class="mt-4 mb-4 w-25"
    ></v-img>

    <div class="d-flex ga-3">
      <v-text-field
        v-model="startDate"
        label="Начална дата"
        type="date"
        required
        :rules="[rules.required]"
        :error-messages="startDateError"
      ></v-text-field>
      <v-text-field
        v-model="endDate"
        label="Крайна дата"
        type="date"
        required
        :rules="[rules.required]"
        :error-messages="endDateError"
      ></v-text-field>
    </div>

    <div class="d-flex ga-5">
      <v-btn type="submit" color="primary">Добави банер</v-btn>
      <!-- <v-btn color="warning" @click="saveDraft">Запази като чернова</v-btn> -->
      <v-btn color="error" @click="cancel">Отказ</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

import { useBannerStore } from '@/stores/bannerStore';
import { formatDateForInput } from '@/stores/dateUtils';

import { BannerPosition, NewBannerFormValues } from '../../models/banner';
import imageCompression from 'browser-image-compression';
import { useField, useForm } from 'vee-validate';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { useRouter } from 'vue-router';
import * as yup from 'yup';

const router = useRouter();
const bannerStore = useBannerStore();

interface BannerIndexes {
  main: number[];
  side: number[];
  content: number[];
}

const bannersIndexes = ref<BannerIndexes>({
  main: [],
  side: [],
  content: [],
});

onMounted(async () => {
  const indexes = await bannerStore.getBannerIndexes();
  bannersIndexes.value = indexes || {
    main: [],
    side: [],
    content: [],
  };
});

const { value: position, errorMessage: positionsError } = useField<BannerPosition | null>(
  'position',
);

const getNextPositionIndex = (position: BannerPosition): number => {
  const currentIndexes = bannersIndexes.value[position];
  return currentIndexes.length ? Math.max(...currentIndexes) + 1 : 1;
};

const minPositionIndex = () => 1;

const maxPositionIndex = () => {
  if (!position.value) return 1;
  return getNextPositionIndex(position.value);
};

watch(position, (newPosition) => {
  if (newPosition) {
    positionIndex.value = getNextPositionIndex(newPosition);
  }
});

const schema = yup.object({
  title: yup.string().min(2, 'Заглавието на банера трябва да е поне 2 символа'),
  description: yup.string(),
  url: yup
    .string()
    .min(2, 'Линка на банера трябва да е поне 2 символа')
    .test('url-or-tel', 'Невалиден URL адрес или телефонен номер', (value) => {
      if (!value) return false;
      
      if (value.startsWith('tel:')) {
        const phoneNumber = value.substring(4);
        return /^\d{6,15}$/.test(phoneNumber);
      }
      
      try {
        new URL(value);
        return true;
      } catch {
        return /^\/[a-zA-Z0-9\u0400-\u04FF\-_\/?=&%]+$/.test(value);
      }
    })
    .required('Линка на банера е задължителен'),
  positionIndex: yup
    .number()
    .required('Поредният номер на банера е задължителен')
    .min(1, 'Поредният номер трябва да е поне 1')
    .test('validPosition', 'Невалиден пореден номер', function(value) {
      if (!position.value || !value) return true;
      const maxAllowed = getNextPositionIndex(position.value);
      return value <= maxAllowed;
    }),
  image: yup
    .mixed()
    .required('Изображението е задължително')
    .test('fileType', 'Невалиден тип файл', value => {
      if (value && typeof value === 'object' && 'type' in value) {
        return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type as string);
      } else {
        return false;
      }
    }),
  position: yup.mixed().required('Позицията на банера е задължителна'),
  startDate: yup.date().required('Началната дата е задължителна'),
});

const { handleSubmit } = useForm<NewBannerFormValues>({
  validationSchema: schema,
  initialValues: bannerStore.bannerForm,
});

function cancel() {
  bannerStore.resetForm();
  router.push({ name: 'banners' });
}

const { value: title, errorMessage: titleError } = useField('title');
const { value: description, errorMessage: descError } = useField('description');
const { value: url, errorMessage: urlError } = useField('url');
const { value: positionIndex, errorMessage: positionIndexError } =
  useField<number>('positionIndex');
const { value: image, errorMessage: imageError } = useField<File | null>('image');
const { value: startDate, errorMessage: startDateError } = useField('startDate');
const { value: endDate, errorMessage: endDateError } = useField('endDate');

const rules = {
  required: (value: any) => !!value || 'Това поле е задължително',
};

async function compressImage(file: File) {
  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
    fileType: 'image/webp',
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    toast.error('Неуспешно компресиране на изображението');
    return file;
  }
}

async function onImageChange(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0] || null;
  if (file) {
    const compressedFile = await compressImage(file);
    image.value = compressedFile;
    bannerStore.setImagePreview(URL.createObjectURL(compressedFile));
  } else {
    bannerStore.setImagePreview('');
  }
}

async function submitForm() {
  handleSubmit(async formValues => {
    try {
      const isImageFile =
        formValues.image && typeof formValues.image === 'object' && 'size' in formValues.image;
      if (isImageFile) {
        const reader = new FileReader();
        reader.readAsDataURL(formValues.image as unknown as File);
        reader.onload = async () => {
          await bannerStore.createBanner(formValues);
          toast.success('Банерът е успешно добавен!');
          title.value = '';
          description.value = '';
          url.value = '';
          image.value = null;
          bannerStore.setImagePreview('');
          startDate.value = formatDateForInput(new Date());
          endDate.value = formatDateForInput(new Date());
          router.push({ name: 'banners' });
        };
      } else {
        toast.error('Изображението не е валидно');
      }
    } catch (error: any) {
      toast.error(error.message || 'Грешка при добавяне на банера');
    }
  })();
}

onMounted(() => {
  bannerStore.resetForm();
});
</script>
