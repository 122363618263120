import agent from '@/api/agent';
import type { Category, CategoryUpdateFormValues, NewCategoryFormValues } from '@/models/category';

import { defineStore } from 'pinia';

interface CategoryState {
  categories: Map<number, Category> | null;
  category: Category | null;
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    categories: new Map(),
    category: null,
  }),
  getters: {
    groupedCategoriesArray: state => {
      const flattenCategories = (category: Category): Category[] => {
        const children = category.children ? category.children.flatMap(flattenCategories) : [];
        return [category, ...children];
      };

      return Array.from(state.categories?.values() || []).flatMap(flattenCategories);
    },
    categoriesArray: state => {
      return Array.from(state.categories?.values() || []);
    },
  },
  actions: {
    async getAllCategories() {
      try {
        const categories = (await agent.Categories.getAllCategories()).data;
        categories.forEach(category => {
          this.categories?.set(category.id, category);
        });
        return this.categories;
      } catch (error) {
        console.error('Error fetching categories:', error);
        throw new Error('Грешка при зареждане на категориите');
      }
    },
    async getCategory(id: number) {
      try {
        const category = (await agent.Categories.getCategory(id)).data;

        if (!category) {
          throw new Error('Категорията не беше намерена');
        }

        this.category = category;
        return this.category;
      } catch (error) {
        throw new Error('Грешка при зареждане на категорията');
      }
    },
    async createCategory(cat: NewCategoryFormValues) {
      try {
        const category = (await agent.Categories.addCategory(cat)).data;
        this.$state.categories?.set(category.id, category);
      } catch (error) {
        console.error('Error creating category:', error);
      }
    },
    async deleteCategory(id: number) {
      try {
        await agent.Categories.deleteCategory(id);
        this.categories?.delete(id);
        // this.getAllCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    },
    async updateCategory(cat: CategoryUpdateFormValues) {
      try {
        const category = (await agent.Categories.updateCategory(cat)).data;
        this.$state.categories?.set(category.id, category);
      } catch (error) {
        console.error('Error updating category:', error);
      }
    },
    async updateCategoryHierarchy(categories: number[], newParentId: number) {
      try {
        await agent.Categories.updateCategoryHierarchy(categories, newParentId);
        this.getAllCategories();
      } catch (error) {
        console.error('Error updating category hierarchy:', error);
      }
    },
  },
});
